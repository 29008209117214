// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "variables";
@import "@angular/material/theming";
// @import "~ngx-org-chart/_theming.scss";
// Angular-Calendar
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import 'vendors/variables';
// D:\2.0\admin-ui\src\vendors\_variables.scss
// Plus imports for other components in your app.
$mvm2-typography: mat.define-typography-config($font-family: '"Urbanist", sans-serif',
  );

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($mvm2-typography);

$dynamic-light-theme: mat-light-theme($theme-primary-palette, $theme-accent-palette);
$dynamic-dark-theme: mat-dark-theme($theme-primary-palette, $theme-accent-palette);

$primary: map-get($dynamic-light-theme, primary);
$accent: map-get($dynamic-light-theme, accent);
$hover: mat-palette(map-get($dynamic-light-theme, primary), 700);
$hover-light: mat-palette(map-get($dynamic-light-theme, primary), 50);

$primary-dark: map-get($dynamic-dark-theme, primary);
$accent-dark: map-get($dynamic-dark-theme, accent);
$hover-dark: mat-palette(map-get($dynamic-dark-theme, primary), 700);
$hover-light-dark: mat-palette(map-get($dynamic-dark-theme, primary), 50);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mvm2-primary: mat.define-palette(mat.$indigo-palette);
$mvm2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mvm2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $mvm2-theme: mat.define-light-theme(
//     (
//         color: (
//             primary: $mvm2-primary,
//             accent: $mvm2-accent,
//             warn: $mvm2-warn,
//         ),
//         typography: $mvm2-typography,
//     )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

.theme-dependent-colors {
  background: mat-color($primary);
  color: mat-color($accent);
}

.theme-variables {
  --theme-primary: #{mat-color($primary)};
  --theme-accent: #{mat-color($accent)};
  --theme-hover: #{mat-color($hover)};
  --theme-hover-light: #{mat-color($hover-light)};
  --theme-accent-color-500: #fb9400;
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  --theme-background-color: #ffffff;
  --theme-border-color: #BDBDBD;
  --theme-color: #000000;
  --theme-icon-color: invert(79%) sepia(0%) saturate(153%) hue-rotate(184deg) brightness(80%) contrast(87%);
  --theme-hover-color: #dbdbdb;
  --theme-calendar-border: #DCDFE3;
  --theme-calendar-header-text: #3B3E45;
  --theme-calendar-close: #F5F5F5;
  --theme-calendar-out: #F8F9FA;
  --theme-appointment: #F3F4F6;
  --theme-drag: #FFF8ED;
  --theme-date-picker: #FAFAFA;
  --theme-scroll-top: #c6c0c0;
  --theme-event-bg: #f3f4f6;
  --theme-event-title: #3b3e45;
  --theme-event-para: #666e70;
  --theme-card-bg: #F8F9FA;
  --theme-legend-icon: invert(69%) sepia(0%) saturate(4293%) hue-rotate(322deg) brightness(97%) contrast(76%);
  --theme-icon-red: invert(33%) sepia(92%) saturate(6884%) hue-rotate(354deg) brightness(109%) contrast(126%);
  --theme-search-bg: #F5F5F5;
  --theme-icon-back: invert(0%) sepia(0%) saturate(7496%) hue-rotate(258deg) brightness(109%) contrast(98%);
  @include angular-material-theme($dynamic-light-theme);
}

.theme-variables-dark {
  --theme-primary: #{mat.get-color-from-palette($primary-dark)};
  --theme-accent: #{mat.get-color-from-palette($accent-dark)};
  --theme-hover: #{mat.get-color-from-palette($hover-dark)};
  --theme-hover-light: #{mat.get-color-from-palette($hover-light-dark)};
  --theme-accent-color-500: #fb9400;
  background-color: #181A20;
  color: #ffffff;
  --theme-background-color: #181A20;
  --theme-color: #ffffff;
  --theme-menu-bg: rgba(211, 211, 211, 0.116);
  --theme-icon-color: invert(79%) sepia(0%) saturate(153%) hue-rotate(184deg) brightness(80%) contrast(87%);
  --theme-hover-color: #545554;
  --theme-calendar-border: #dcdfe3af;
  --theme-calendar-header-text: #FB9400;
  --theme-calendar-out: #3B3C42;
  --theme-calendar-close: #424242;
  --theme-appointment: #3B3C42;
  --theme-drag: #322D25;
  --theme-date-picker: #35383F;
  --theme-scroll-top: #807d7d;
  --theme-event-bg: #212121;
  --theme-event-title: #fb9400;
  --theme-event-para: #9e9e9e;
  --theme-card-bg: #1F222A;
  --theme-legend-icon: invert(45%) sepia(6%) saturate(1023%) hue-rotate(181deg) brightness(92%) contrast(86%);
  --theme-icon-color: invert(79%) sepia(0%) saturate(153%) hue-rotate(184deg) brightness(80%) contrast(87%);
  --theme-icon-red: invert(33%) sepia(92%) saturate(6884%) hue-rotate(354deg) brightness(109%) contrast(126%);
  --theme-search-bg: #35383F;
  --theme-icon-back: invert(99%) sepia(1%) saturate(2%) hue-rotate(84deg) brightness(106%) contrast(100%);
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  @include mat.all-component-themes($dynamic-dark-theme);
}

// @include mat.all-component-themes($dynamic-light-theme);

/* You can add global styles to this file, and also import other style files */

// * {
//     box-sizing: border-box;
// }

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.flex {
  display: flex !important;
}

.flex-horizontal {
  flex-direction: column;
}

.clear {
  clear: both;
}

.text-center {
  text-align: center;
}

.safari-height {
  min-height: 100vh;
}

.m20 {
  margin: 20px;
}

.no-scroll {
  overflow: hidden;
}

.no-pointer {
  pointer-events: none !important;
}

.mc {
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

textarea {
  cursor: text !important;
}

.error-snackbar {
  color: #F75555 !important;
  background-color: rgb(253, 221, 221) !important;
  box-shadow: none !important;
  margin-top: 60px !important;
  margin-left: 81px !important;
}

.error-snackbar .mat-simple-snackbar-action {
  color: #F75555 !important;
}

.modi-error-snackbar {
  color: black !important;
  background-color: white !important;
  box-shadow: none !important;
}

.modi-error-snackbar .mat-simple-snackbar-action {
  color: black !important;
}

.success-snackbar {
  color: #4AAF57 !important;
  background-color: #c0f0d2 !important;
  box-shadow: none !important;
  margin-top: 60px !important;
  margin-left: 81px !important;
}

.success-snackbar .mat-simple-snackbar-action {
  color: #4AAF57 !important;
}

//matltel-input
.mat-menu-content:not(:empty) {
  max-height: 300px !important;
  max-width: 100% !important;
  min-width: 90% !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  border-radius: 4px !important;
  outline: 0 !important;
  // min-height: 64px !important;
}

.country-selector {
  opacity: 1 !important;
  margin-left: -19px !important;
}

.country-list-button {
  font-size: 0.8rem !important;
}

.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-button-focus-overlay {
  opacity: 0 !important;
  transition: none !important;
  cursor: text !important;
}

//phone input
.country-search {
  height: 0px !important;
}

.theme-variables-dark .country-search {
  background-color: #303030 !important;
  color: white;
}

.theme-variables-dark .label-wrapper {
  color: white;
}

.theme-variables-dark .mat-menu-item {
  background-color: #303030 !important;
  background-color: #424242 !important;
}

.theme-variables-dark .mat-button .mat-button-wrapper .country-selector-code {
  color: white !important;
}

.custom-style {
  min-width: 348px !important;
  border-radius: 25px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@media screen and (max-width: 1023px) {
  .custom-style {
    min-width: 300px !important;
  }


  //side image
  .side-image {
    width: 50% !important;
  }
}


//input field for desktop

//large input
.custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
  min-width: 12px !important;
}

.custom-input-lg.mat-form-field {
  width: 95% !important;
}

.custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: rgba(251, 148, 0, 0.08) !important;
  border-radius: 12px !important;
}

.custom-input-lg .mat-form-field-outline {
  background-color: #F5F5F5;
  border-radius: 12px !important;
  margin-top: 4px;
}

.custom-input-lg.mat-form-field-appearance-outline .mat-form-field-flex {
  height: 56px !important;
  margin-top: -0.75em;
}

.theme-variables-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: #303030 !important;
  border-radius: 12px !important;
}

.theme-variables-dark .mat-form-field-outline {
  background-color: #303030;
  color: #ffffff;
  border-radius: 12px !important;
}


//small input

.custom-input-sm.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.custom-input-sm.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
  min-width: 12px !important;
}

.custom-input-sm.mat-form-field {
  width: 90px !important;
}

.custom-input-sm.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: rgba(251, 148, 0, 0.08) !important;
  border-radius: 12px !important;
}

.custom-input-sm .mat-form-field-outline {
  background-color: #F5F5F5;
  border-radius: 12px !important;
  margin-top: 4px;
}

.custom-input-sm.mat-form-field-appearance-outline .mat-form-field-flex {
  height: 50px !important;
  margin-top: -0.75em;
}

.custom-input-sm.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0.6em;
}

.theme-variables-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: #303030 !important;
  border-radius: 12px !important;
}

.theme-variables-dark .mat-form-field-outline {
  background-color: #303030;
  color: #ffffff;
  border-radius: 12px !important;
}


//medium input
.custom-input-md.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.custom-input-md.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
  min-width: 12px !important;
}

.custom-input-md.mat-form-field {
  width: 240px !important;
}

.custom-input-md.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: rgba(251, 148, 0, 0.08) !important;
  border-radius: 12px !important;
}

.custom-input-md .mat-form-field-outline {
  background-color: #F5F5F5;
  border-radius: 12px !important;
  margin-top: 4px;
}

.custom-input-md.mat-form-field-appearance-outline .mat-form-field-flex {
  height: 52px !important;
  margin-top: -0.75em;
}

.custom-input-md.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0.4em;
}


//side image
.side-image {
  width: 50%;
}

//mvmlogo
.product-logo {
  width: 222px;
  height: 25px;
}

//icon spacing
.icon-space {
  padding: 0px 17px 0 9px !important;
}

.mat-icon {
  height: 20px !important;
  width: 20px !important;
}

//hint text for social logins
.hint-text-md {
  font-weight: 400;
  font-style: normal;
  color: var(--theme-color);
}


//Buttons for desktop

//large buttons

.custom-button-lg {
  width: 300px;
  height: 40px;
  border-radius: 30px !important;
}

//medium buttons

.custom-button-md {
  width: 200px;
  height: 40px;
  border-radius: 30px !important;
  text-align: center;
}

//small buttons

.custom-button-sm {
  width: 100px;
  height: 40px;
  border-radius: 30px !important;
  font-size: 13px;
}

// icon color setup
.mat-icon {
  filter: var(--theme-icon-color) !important;
}

.mat-tab-label-active {
  filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  opacity: unset !important;
  box-shadow: inset 0px transparent !important;
}

.mat-list-single-selected-option {
  .mat-icon {
    filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  }
}

.mat-focused {
  .mat-icon {
    filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  }
}

//For autofill background color change
.theme-variables-dark input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

//for aligning input content
.mat-input-element {
  margin-bottom: 2px !important;
}


@media screen and (max-width: 640px) {

  //input field for mobile
  //large input
  .custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 12px 0 0 12px !important;
    min-width: 12px !important;
  }

  .custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 12px 12px 0 !important;
    min-width: 12px !important;
  }

  .custom-input-lg.mat-form-field {
    width: 90% !important;
  }
}

.theme-variables-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  background-color: #303030 !important;
  border-radius: 12px !important;
}

.theme-variables-dark .mat-form-field-outline {
  background-color: #303030;
  color: #ffffff;
  border-radius: 12px !important;
}


//side image
.side-image {
  width: 50%;
}

//mvmlogo
.product-logo {
  width: 222px;
  height: 25px;
}

//icon spacing
.icon-space {
  padding: 0px 17px 0 9px !important;
}

.mat-icon {
  height: 20px !important;
  width: 20px !important;
}

//sub heading
.sub-header {
  font-weight: 500 !important;
  font-size: 15px !important;
}

//hint text for social logins
.hint-text-md {
  font-weight: 400;
  font-style: normal;
  color: var(--theme-color);
}


//Buttons for desktop

//large buttons

.custom-button-lg {
  width: 300px;
  height: 40px;
  border-radius: 30px !important;
}

//medium buttons

.custom-button-md {
  width: 200px;
  height: 40px;
  border-radius: 30px !important;
  text-align: center;
}

//small buttons

.custom-button-sm {
  width: 100px;
  height: 40px;
  border-radius: 30px !important;
  font-size: 12px;
}

// icon color setup
.mat-icon {
  filter: var(--theme-icon-color) !important;
}

.mat-tab-label-active {
  filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  box-shadow: inset 0px transparent !important;
}

.mat-list-single-selected-option {
  border: 1px solid var(--theme-primary) !important;
  color: var(--theme-primary) !important;
  border-radius: 8px !important;

  .mat-icon {
    filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  }

  .text-small {
    color: var(--theme-primary);
  }
}

.mat-focused {
  .mat-icon {
    filter: invert(53%) sepia(63%) saturate(1764%) hue-rotate(5deg) brightness(103%) contrast(102%) !important;
  }
}

//For autofill background color change
.theme-variables-dark input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

//for aligning input content
.mat-input-element {
  margin-bottom: 2px !important;
}


@media screen and (max-width: 640px) {

  //input field for mobile
  //large input
  .custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 12px 0 0 12px !important;
    min-width: 12px !important;
  }

  .custom-search-pagination {
    .mat-form-field {
      width: 90% !important;
    }
  }

  .custom-input-lg.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 12px 12px 0 !important;
    min-width: 12px !important;
  }

  .custom-input-lg.mat-form-field {
    width: 90% !important;
  }

  .theme-variables-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    background-color: #303030 !important;
    border-radius: 12px !important;
  }

  .theme-variables-dark .mat-form-field-outline {
    background-color: #303030;
    color: #ffffff;
    border-radius: 12px !important;
  }

  //Buttons for mobile
  .custom-button-lg {
    width: 60%;
    height: 50px;
    border-radius: 30px !important;
  }

  .custom-button-md {
    width: 80% !important;
    height: 50px;
    border-radius: 30px !important;
  }
}


@media screen and (min-width: 641px) and (max-width: 1023px) {

  //input field for tab
  .custom-input-lg.mat-form-field-appearance-outline .mat-form-field-flex {
    height: 60px !important;
  }

  .custom-input-lg.mat-form-field {
    width: 80% !important;
  }

  //button for tab
  .custom-button-md {
    width: 400px !important;
    height: 50px;
    border-radius: 30px !important;
    text-align: center;
  }
}

// spinner
.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    --d: 22px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    color: #FB9400;

    box-shadow:
      calc(1*var(--d)) calc(0*var(--d)) 0 0,
      calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
      calc(0*var(--d)) calc(1*var(--d)) 0 2px,
      calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
      calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
      calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
      calc(0*var(--d)) calc(-1*var(--d)) 0 6px;
    animation: s7 1s infinite steps(8);
  }

  @keyframes s7 {
    100% {
      transform: rotate(1turn)
    }
  }
}


.theme-variables-dark .mat-table {
  background: none !important;
}

@keyframes s7 {
  100% {
    transform: rotate(1turn)
  }
}

.custom-dialog .mat-dialog-container {
  border-radius: 25px;
}

//for changing the border color of the input when we hover
.theme-variables .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--theme-primary);
}

// scroll bar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--theme-scroll-top);
}

::-webkit-scrollbar-thumb::vertical {
  height: 10px !important;
  /* Set a minimum height */
  position: relative;
}

// snackbar UI
.mat-snack-bar-container {
  padding: 10px 12px !important;
  border-radius: 10px !important;
  min-height: 35px !important;

  .mat-simple-snackbar {
    font-size: 12px;
    font-weight: 500;
  }

  .mat-button-wrapper {
    font-size: 12px;
    font-weight: 500;
  }

  color: var(--theme-primary);
}

.theme-variables-dark .mat-table {
  background: none !important;

  @keyframes s7 {
    100% {
      transform: rotate(1turn)
    }
  }
}


.custom-search-pagination {
  .mat-form-field {
    width: 50%;
  }

  .mat-form-field-outline {
    border-radius: 12px !important;
    margin-top: 4px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      background-color: var(--theme-search-bg);
    }

    .mat-form-field-outline-start {
      min-width: 12px !important;
      border-radius: 12px 0 0 12px !important;
      border: 1px solid transparent;
    }

    .mat-form-field-outline-end {
      min-width: 12px !important;
      border-radius: 0 12px 12px 0 !important;
      border: 1px solid transparent;
    }


    .mat-form-field-flex {
      height: 50px !important;
      margin-top: -0.75em;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0;
    }
  }

  input.mat-input-element {
    margin-top: -3px;
  }

  .mat-focus,
  .mat-form-field-should-float {
    .mat-form-field-outline {
      border: 1px solid var(--theme-primary) !important;
    }

  }

  .mat-form-field-outline-thick {
    border-radius: 12px !important;
  }



  mat-icon {
    width: 15px !important;
    height: 15px !important;
  }
}


.search-pagination {
  .mat-form-field {
    width: 75% !important;
    cursor: text !important;

  }

  .mat-form-field-outline {
    border-radius: 12px !important;
    margin-top: 4px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      background-color: var(--theme-search-bg);
    }

    .mat-form-field-outline-start {
      min-width: 12px !important;
      border-radius: 12px 0 0 12px !important;
      border: 1px solid transparent;
    }

    .mat-form-field-outline-end {
      min-width: 12px !important;
      border-radius: 0 12px 12px 0 !important;
      border: 1px solid transparent;
    }


    .mat-form-field-flex {
      height: 50px !important;
      margin-top: -0.75em;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0;
    }
  }

  input.mat-input-element {
    margin-top: -3px;
  }

  .mat-focus,
  .mat-form-field-should-float {
    .mat-form-field-outline {
      border: 1px solid var(--theme-primary) !important;
    }

  }

  .mat-form-field-outline-thick {
    border-radius: 12px !important;
  }



  mat-icon {
    width: 15px !important;
    height: 15px !important;
  }
}

.search-md {
  .mat-form-field {
    width: 45% !important;
    cursor: text !important;

  }

  .mat-form-field-outline {
    border-radius: 12px !important;
    margin-top: 6px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      background-color: var(--theme-search-bg);
    }

    .mat-form-field-outline-start {
      min-width: 12px !important;
      border-radius: 12px 0 0 12px !important;
      border: 1px solid transparent;
    }

    .mat-form-field-outline-end {
      min-width: 12px !important;
      border-radius: 0 12px 12px 0 !important;
      border: 1px solid transparent;
    }


    .mat-form-field-flex {
      height: 50px !important;
      margin-top: 0;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0;
    }
  }

  input.mat-input-element {
    margin-top: -3px;
  }

  .mat-focus,
  .mat-form-field-should-float {
    .mat-form-field-outline {
      border: 1px solid var(--theme-primary) !important;
    }

  }

  .mat-form-field-outline-thick {
    border-radius: 12px !important;
  }



  mat-icon {
    width: 15px !important;
    height: 15px !important;
  }

  .mat-form-field .mat-form-field-prefix .mat-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    width: 2.5em;
    height: 2.5em;
    cursor: text !important;
  }
}

.status-active-icon {
  color: rgba(74, 222, 128, 1) !important;
  filter: none !important;
  font-size: 15px;
}

.status-inactive-icon {
  color: rgba(247, 85, 85, 1) !important;
  filter: none !important;
  font-size: 15px;
}

.status-gracePeriod {
  color: #FFEB3B !important;
}

.status-trialPeriod {
  color: #8BC255 !important;
}

.status-subExpiry {
  color: #7a98a7 !important;
}

.status-pending-icon {
  color: var(--theme-primary) !important;
  filter: none !important;
  font-size: 15px;
}

.active-status {
  color: #4ade80 !important;
}

.inactive-status {
  color: #f54336 !important;
}

.normal-status {
  color: white !important;
}

.stripe-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* Adds space between lines */
}

.stripe-form .StripeElement {
  width: 100%;
  /* Ensure that each element takes the full width */
  margin-bottom: 1rem;
  /* Adds space below each field */
}

.mat-option-container {
  width: 212px !important;
}

.mat-option{
  width:160px !important;
}

//style for table heading
.mat-header-cell {
  display: flex !important;
  align-items: center !important;
  justify-content: start;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

//style for table cells
.mat-cell, .mat-footer-cell {
  display: flex !important;
  align-items: center !important;
  justify-content: start;
}

//for mat head background color
mat-header-row{
 // background-color: #312719 !important;
  background-color: #181A20 !important;
}

//height for the all table row
mat-row, mat-footer-row {
  min-height: 65px !important;
}



