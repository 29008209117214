// Variable overrides

.cal-events-container{
  margin-right: 2px;
}

// theme changes
.cal-week-view {
  background-color: var(--theme-background-color) !important;
  border-top: 0;

  .cal-day-column {
    border-left-color: var(--theme-calendar-border);
  }


  .cal-day-headers {
    border: 0;

    .cal-header {
      padding: 0 0 6px 18px !important;
    }

    .cal-header:hover,
    .cal-drag-over {

      background-color: transparent !important;
    }

    b {
      color: var(--theme-calendar-header-text);
      font-weight: 500;
    }

    span {
      font-size: 12px;
    }

    .cal-header:not(:last-child) {
      border-right-color: var(--theme-calendar-border) !important;
      border-left-color: var(--theme-calendar-border) !important;
    }
  }

  .cal-today {
    background-color: transparent !important;
  }


  .cal-hour-odd {
    background-color: var(--theme-background-color) !important;
    border-bottom: var(--theme-calendar-border) !important;
  }

  .cal-time-events {
    border: 0;

    .cal-day-columns:not(.cal-resize-active) {
      .cal-hour-segment:hover {
        background-color: transparent !important;
      }
    }

    .cal-time {
      color: var(--theme-calendar-header-text);
      font-weight: 300;
      font-size: 12px;
    }

    .cal-resize-handle-before-start {
      margin-left: -2px !important;
      top: -3px !important;
    }

    .cal-resize-handle {
      cursor: row-resize;
      position: absolute;
      height: 12px !important;
      margin-right: -2px !important;
      background-color: var(--theme-primary-color-500);
      width: 12px !important;
      border-radius: 50%;
    }

    .cal-resize-handle-after-end {
      bottom: -3px !important;
      right: 0px !important;
    }
  }

  .cal-hour-start {
    border-top: 1px solid var(--theme-calendar-border) !important;
    border-bottom: 0 !important;
  }

  .cal-header.cal-weekend span {
    color: var(--theme-color) !important;
  }

}

// backdrop filter color
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgb(52 50 50 / 60%) !important;
}
